import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'

import {
  Input,
  Dropdown,
  Checkbox,
  DateInput,
  TimeInput,
  TextArea,
} from '@components/common/form'
import YSpacing from '@components/common/YSpacing'
import XSpacing from '@components/common/XSpacing'
import FlexContainer from '@components/common/FlexContainer'
import { IoCaretDownCircle, IoCaretForwardCircle } from 'react-icons/io5'
import DividerLine from '@components/common/DividerLine'
import { OrderFormSectionTitle, Label } from '@res/styledComponents/index'
import RichTextView from './RichTextView'
import { colors, DEFAULT_CLEANUP_FEE } from '../../../../../constants'

class OrderInfoSection extends Component {
  state = {
    isCollapseCustomizedSection: true,
  }

  componentDidMount() {
    const { allOrderTypes, allSetUpStyles, allServiceTypes } = this.props
    const additionalState = {}
    if (!this.props.orderType) {
      additionalState.orderType = allOrderTypes[0].value
    }
    if (!this.props.setUpStyle) {
      additionalState.setUpStyle = allSetUpStyles[0].value
    }
    if (!this.props.serviceType) {
      additionalState.serviceType = allServiceTypes[0].value
    }
    if (Object.keys(additionalState).length > 0) {
      this.props.onChange(additionalState)
    }
  }

  onInput = (field) => (value) => {
    if (value.target) {
      ;({ value } = value.target)
    }

    this.props.onChange({ [field]: value })
  }

  onChangeNeedsCleanup = (e) => {
    const needsCleanup = e.target.checked
    const cleanupFee = needsCleanup ? DEFAULT_CLEANUP_FEE : 0.0

    this.props.onChange({
      needsCleanup,
      cleanupFee,
      serviceInputsChanged: true,
    })
  }

  onChangeHeadCount = (e) => {
    const headCount = e.target.value
    this.props.changePortions({ headCount })
  }

  onSelectChargeDate = (chargeDate) => {
    chargeDate.hour(23).minute(30)
    this.props.onChange({ chargeDate })
  }

  onSelectOrderType = (e) => {
    const orderType = e.target.value
    this.props.onSelectOrderType(orderType)
  }

  onSelectSetupDate = (clientSetUpTime) => {
    this.props.onSelectSetupDate(clientSetUpTime)
  }

  renderLastUpdated = (by, at) => {
    return <p>Last Updated By: {`${by || ''} ${at || ''}`}</p>
  }

  render() {
    const {
      allOrderTypes,
      allSetUpStyles,
      allServiceTypes,
      chargeDate,
      clientSetUpTime,
      clientDoNotArriveBeforeTime,
      headCount,
      carbonNeutral,
      deliveryInstructions,
      needsCleanup,
      needsStaffing,
      orderType,
      orderTypeDisabled,
      purchaseOrderNumber,
      setUpInstructions,
      serviceType,
      setUpStyle,
      serviceNotes,
      clientNotes,
      cuisinePreferences,
      clientSuccessMetrics,
      lastUpdatedAtClientNotes,
      lastUpdatedAtCuisinePreferences,
      lastUpdatedAtClientSuccessMetrics,
      lastUpdatedByClientNotes,
      lastUpdatedByCuisinePreferences,
      lastUpdatedByClientSuccessMetrics,

      errors,

      onCheckCarbonNeutral,
    } = this.props

    const { isCollapseCustomizedSection } = this.state

    return (
      <div>
        <DividerLine margin="30px 0" />
        <OrderFormSectionTitle>
          <span>2</span>Enter Your Order Info
        </OrderFormSectionTitle>
        <YSpacing height="20px" />
        <FlexContainer width="100%" justifyContent="space-between">
          <DateInput
            label="Delivery Date"
            date={clientSetUpTime}
            width="31%"
            onChange={this.onSelectSetupDate}
            hideClearDate={true}
          />
          <Input
            label="# of People" // Price
            marginBottom="0"
            type="number"
            width="31%"
            error={errors.headCount}
            value={headCount}
            onChange={this.onChangeHeadCount}
          />
          <Dropdown
            error={errors.orderType}
            label="Meal Type"
            width="31%"
            marginBottom="0"
            value={orderType}
            onChange={this.onSelectOrderType}
            disabled={orderTypeDisabled}
          >
            {allOrderTypes.map((type) => (
              <option key={type.value}>{type.text}</option>
            ))}
          </Dropdown>
        </FlexContainer>
        <YSpacing height="20px" />
        <FlexContainer width="100%" justifyContent="space-between">
          <TimeInput
            width="31%"
            label="Setup Complete By"
            time={clientSetUpTime}
            onChange={this.onInput('clientSetUpTime')}
          />
          <TimeInput
            width="31%"
            label="Do Not Arrive Before Time"
            time={clientDoNotArriveBeforeTime}
            onChange={this.onInput('clientDoNotArriveBeforeTime')}
            onClear={() =>
              this.props.onChange({ clientDoNotArriveBeforeTime: null })
            }
          />
          <Dropdown
            label="Presentation Set-Up"
            width="31%"
            marginBottom="0"
            value={setUpStyle}
            onChange={this.onInput('setUpStyle')}
          >
            {allSetUpStyles.map((s) => (
              <option key={s.value}>{s.text}</option>
            ))}
          </Dropdown>
        </FlexContainer>
        <YSpacing height="20px" />
        <FlexContainer
          width="100%"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <DateInput
            width="31%"
            label="Charge Date"
            date={chargeDate}
            onChange={this.onSelectChargeDate}
            error={errors['Charge Date']}
            hideClearDate={true}
          />

          <Input
            label="PO Number / Reference" // Price
            marginBottom="0"
            width="31%"
            value={purchaseOrderNumber}
            onChange={this.onInput('purchaseOrderNumber')}
          />
          <FlexContainer
            width="31%"
            alignItems="flex-start"
            justifyContent="flex-end"
            flexDirection="column"
          >
            <Label>Additional Service Fees</Label>
            <YSpacing height="5px" />
            <Checkbox
              label="Carbon Neutral?"
              value={carbonNeutral}
              checked={carbonNeutral}
              onChange={onCheckCarbonNeutral}
            />
            <YSpacing height="5px" />
            <Checkbox
              label="Needs Cleanup?"
              value={needsCleanup}
              checked={needsCleanup}
              onChange={this.onChangeNeedsCleanup}
            />
            <YSpacing height="5px" />
            <Checkbox
              label="Needs Staffing?"
              value={needsStaffing}
              checked={needsStaffing}
              onChange={(e) => this.onInput('needsStaffing')(e.target.checked)}
            />
            <YSpacing height="5px" />
          </FlexContainer>
        </FlexContainer>
        <YSpacing height="20px" />
        <FlexContainer
          width="100%"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Dropdown
            label="Service Type"
            width="31%"
            marginBottom="0"
            value={serviceType}
            onChange={this.onInput('serviceType')}
          >
            {allServiceTypes.map((s) => (
              <option key={s.value}>{s.text}</option>
            ))}
          </Dropdown>
        </FlexContainer>
        <YSpacing height="20px" />
        <FlexContainer alignItems="center">
          <h2 className="sidebar-body-heading">
            Customized Settings for Catering Orders
          </h2>
          <XSpacing width="20px" />
          {isCollapseCustomizedSection ? (
            <IoCaretForwardCircle
              color={colors.orange}
              size={25}
              onClick={() =>
                this.setState({
                  isCollapseCustomizedSection: !isCollapseCustomizedSection,
                })
              }
            />
          ) : (
            <IoCaretDownCircle
              color={colors.orange}
              size={25}
              onClick={() =>
                this.setState({
                  isCollapseCustomizedSection: !isCollapseCustomizedSection,
                })
              }
            />
          )}
        </FlexContainer>
        {!isCollapseCustomizedSection && (
          <FlexContainer flexDirection="column">
            <DividerLine margin="20px 0" />
            <FlexContainer flexDirection="row" alignItems="center">
              <Label fontSize="13px"> *Client Notes </Label>
              <XSpacing width="20px" />
              {this.renderLastUpdated(
                lastUpdatedByClientNotes,
                lastUpdatedAtClientNotes,
              )}
            </FlexContainer>
            <YSpacing height="20px" />
            <TextArea
              width={'100%'}
              height="100px"
              marginBottom={'0px'}
              value={clientNotes}
              disabled={true}
              onChange={(e) =>
                this.onChangeGeneric('clientNotes', e.target.value)
              }
            />
            <YSpacing height="20px" />
            <FlexContainer flexDirection="row" alignItems="center">
              <Label fontSize="13px"> *Cuisine Preferences </Label>
              <XSpacing width="20px" />
              {this.renderLastUpdated(
                lastUpdatedByCuisinePreferences,
                lastUpdatedAtCuisinePreferences,
              )}
            </FlexContainer>
            <TextArea
              width={'100%'}
              height="100px"
              marginBottom={'0px'}
              value={cuisinePreferences}
              disabled={true}
              onChange={(e) =>
                this.onChangeGeneric('cuisinePreferences', e.target.value)
              }
            />
            <YSpacing height="20px" />
            <FlexContainer flexDirection="row" alignItems="center">
              <Label fontSize="13px"> *Client Success Metrics </Label>
              <XSpacing width="20px" />
              {this.renderLastUpdated(
                lastUpdatedByClientSuccessMetrics,
                lastUpdatedAtClientSuccessMetrics,
              )}
            </FlexContainer>
            <TextArea
              width={'100%'}
              height="100px"
              marginBottom={'0px'}
              value={clientSuccessMetrics}
              disabled={true}
              onChange={(e) =>
                this.onChangeGeneric('clientSuccessMetrics', e.target.value)
              }
            />
          </FlexContainer>
        )}

        <YSpacing height="20px" />
        <FlexContainer justifyContent="space-between">
          <TextArea
            label="Default Address Delivery Instructions"
            width="48%"
            value={deliveryInstructions}
            onChange={this.onInput('deliveryInstructions')}
          />
          <TextArea
            label="Invoice Note"
            width="48%"
            value={setUpInstructions}
            onChange={this.onInput('setUpInstructions')}
          />
        </FlexContainer>
        <YSpacing height="20px" />
        <RichTextView
          label="Order Delivery, Parking, & Set Up Instructions"
          width="100%"
          instructions={serviceNotes}
          onChange={this.onInput('serviceNotes')}
        />
      </div>
    )
  }
}

OrderInfoSection.propTypes = {
  allOrderTypes: PropTypes.array,
  allSetUpStyles: PropTypes.array,
  allServiceTypes: PropTypes.array,
  chargeDate: PropTypes.instanceOf(Moment),
  clientSetUpTime: PropTypes.instanceOf(Moment),
  clientDoNotArriveBeforeTime: PropTypes.instanceOf(Moment),
  hasNetPaymentSettings: PropTypes.bool,
  headCount: PropTypes.number,
  carbonNeutral: PropTypes.bool,
  deliveryInstructions: PropTypes.string,
  needsCleanup: PropTypes.bool,
  needsStaffing: PropTypes.bool,
  orderType: PropTypes.string,
  orderTypeDisabled: PropTypes.bool,
  orderableType: PropTypes.string,
  purchaseOrderNumber: PropTypes.string,
  setUpInstructions: PropTypes.string,
  serviceNotes: PropTypes.string,
  serviceType: PropTypes.string,
  setUpStyle: PropTypes.string,
  clientNotes: PropTypes.string,
  cuisinePreferences: PropTypes.string,
  clientSuccessMetrics: PropTypes.string,
  lastUpdatedAtClientNotes: PropTypes.string,
  lastUpdatedAtCuisinePreferences: PropTypes.string,
  lastUpdatedAtClientSuccessMetrics: PropTypes.string,
  lastUpdatedByClientNotes: PropTypes.string,
  lastUpdatedByCuisinePreferences: PropTypes.string,
  lastUpdatedByClientSuccessMetrics: PropTypes.string,

  errors: PropTypes.object,

  changePortions: PropTypes.func,
  loadOrderSettings: PropTypes.func,
  onChange: PropTypes.func,
  onSelectSetupDate: PropTypes.func,
  onSelectOrderType: PropTypes.func,
  onCheckCarbonNeutral: PropTypes.func,
}

OrderInfoSection.defaultProps = {
  errors: {},
}

export default OrderInfoSection
